import NeusaTTF from "./fonts/5500213/038e6d8b-b760-44f0-91b3-f45840e8a8ab.ttf"
import NeusaWOFF from "./fonts/5500213/e46478fd-57d4-4bfd-ab13-72e5d291c33c.woff"
import NeusaEOT from "./fonts/5500213/7bc488b3-e2e8-4102-98c7-f1c5ff5161d5.eot"
import NeusaWOFF2 from "./fonts/5500213/702e1ff6-3f5e-48a1-b2e0-03af8047d7fc.woff2"

const theme = {
  color: {
    text: "rgb(100, 100, 100)",
    bg: {
      dark: "rgb(225, 225, 225)",
      light: "rgb(255, 255, 255)",
    },
    line: "#CCCCCC",
  },
  font: {
    primary: {
      ttf: NeusaTTF,
      woff: NeusaWOFF,
      woff2: NeusaWOFF2,
      eot: NeusaEOT,
    },
  },
  breakpoint: {
    large: "64rem",
  },
}

export default theme
